import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter, takeUntil, tap } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { SwPush } from '@angular/service-worker';
import { fp } from '@origin8-web/o8-utils/fp';

export type OnClickNotificationFn = (options: NotificationOptions['data']) => void;

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  isBrowserNotificationDisabled$ = new BehaviorSubject<boolean>(true);
  unsubscribe$ = new Subject<void>();
  isListeningToClickEvents = false;

  onClickFns: { [key: string]: OnClickNotificationFn } = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
    private swPush: SwPush,
  ) {
    /* For notification click actions based on: https://angular.io/guide/service-worker-notifications */
    this.swPush.notificationClicks
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ notification }) => {
          return typeof this.onClickFns[notification.data.notificationId] === 'function';
        }),
        tap(({ notification }) => {
          this.onClickFns[notification.data.notificationId](notification.data);
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  requestPermission(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (Notification && Notification.permission !== 'granted') {
      Notification.requestPermission().then(() => {
        this.isBrowserNotificationDisabled$.next(Notification.permission !== 'granted');
      });
    } else {
      this.isBrowserNotificationDisabled$.next(false);
    }
  }

  displayNotification(
    title: string,
    options: NotificationOptions & { onClick?: OnClickNotificationFn } = {},
  ): Observable<{ title: string; notificationOptions: NotificationOptions }> {
    const notificationId = `${new Date().getTime()}-`; /* Generating id for the notification that will be used for the onclick fn*/
    if (options.onClick) {
      this.onClickFns[notificationId] = options.onClick;
    }

    return new Observable((observer) => {
      if (Notification.permission == 'granted') {
        navigator.serviceWorker
          .getRegistration()
          .then(function (reg) {
            const data = { ...options.data, notificationId };
            const opts = fp.omit(options, 'onClick', 'data');
            const notificationOptions: NotificationOptions = {
              icon: '/assets/icons/icon-72x72.png',
              ...opts,
              data,
            };
            reg?.showNotification(title, notificationOptions);
            observer.next({
              title,
              notificationOptions,
            });
            observer.complete();
          })
          .catch((error) => {
            console.error('Error occurred on registration', error);
            observer.error('Error occurred on registration');
          });
      } else {
        observer.complete();
      }
    });
    /* Does not work on localhost as it needs an SSL certificate */
  }
}
